import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import {
  Card, Upload, Button, Space, Typography
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import csv from 'csv';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import toastMessage from 'utils/toastMessage';
import PageHeader from 'components/PageHeader';
import {
  importFileStatusRequest,
  selectFileForStatusImportRequest,
} from 'providers/OrderProvider/actions';

import './style.less';

// Constants
const BREADCRUMB_ITEMS = [
  {
    path: '/orders',
    breadcrumbName: '注文一覧',
  },
  {
    breadcrumbName: '注文ステータス一括取込',
  },
];

const CARD_STYLE = {
  maxWidth: 596,
};

const CARD_HEAD_STYLE = {
  fontSize: 20,
  fontWeight: 'bold',
};

/**
 * Step 1 Component - File Upload and Selection
 */
const Step1 = ({ setStep }) => {
  const dispatch = useDispatch();

  const fileList = useSelector(
    (state) => state.orderProvider.statusImport.fileList,
    shallowEqual
  );

  const handleFileBeforeUpload = useCallback(
    (file, curFileList) => {
      if (file?.type !== 'text/csv') {
        toastMessage.error({
          description: 'ファイル拡張子が正しくありません',
        });
        return false;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        csv.parse(e.target.result, (err, data) => {
          if (err) {
            toastMessage.error({ description: err?.message });
            return;
          }

          dispatch(
            selectFileForStatusImportRequest({
              data,
              fileList: curFileList,
            })
          );
        });
      };
      reader.readAsText(file);

      return false;
    },
    [dispatch]
  );

  const handleCancel = useCallback(() => {
    dispatch(replace('/orders'));
  }, [dispatch]);

  const handleUpload = useCallback(() => {
    setStep(2);
  }, [setStep]);

  return (
    <Card
      id="status-import-card"
      className="highlight-card"
      bordered={false}
      title="注文ステータス一括取込"
      style={CARD_STYLE}
      headStyle={CARD_HEAD_STYLE}
    >
      選択したファイル <br />
      <Upload
        beforeUpload={handleFileBeforeUpload}
        fileList={fileList}
        accept=".csv"
        maxCount={1}
        showUploadList={{
          showPreviewIcon: false,
          showRemoveIcon: false,
          showDownloadIcon: false,
        }}
      >
        <Button icon={<UploadOutlined />}>再選扒</Button>
      </Upload>
      <Space size={8} className="full-w flex justify-end">
        <Button type="default" onClick={handleCancel}>
          キャンセル
        </Button>
        <Button type="primary" onClick={handleUpload}>
          アップロード
        </Button>
      </Space>
    </Card>
  );
};

Step1.propTypes = {
  setStep: PropTypes.func.isRequired,
};

/**
 * Step 2 Component - Import Confirmation
 */
const Step2 = () => {
  const dispatch = useDispatch();
  const [isImportLoading, setIsImportLoading] = useState(false);

  const importData = useSelector(
    (state) => state.orderProvider.statusImport.data,
    shallowEqual
  );

  const fileList = useSelector(
    (state) => state.orderProvider.statusImport.fileList,
    shallowEqual
  );

  const handleCancel = useCallback(() => {
    dispatch(replace('/orders'));
  }, [dispatch]);

  const handleImport = useCallback(async () => {
    setIsImportLoading(true);
    try {
      const result = await dispatch(
        importFileStatusRequest({
          file: fileList[0],
          importData,
        })
      );
      dispatch(
        replace(`/orders/status-imports/history/${result?.objectId}?stt=SUCCESS`)
      );
    } catch (error) {
      // Do nothing
    }
    setIsImportLoading(false);
  }, [dispatch, fileList, importData]);

  return (
    <Card
      className="highlight-card"
      bordered={false}
      title="取込前確認"
      style={CARD_STYLE}
      headStyle={CARD_HEAD_STYLE}
    >
      <Typography.Text>
        <p>注文ステータス変更CSVを取り込みます</p>
        <ul style={{ color: 'rgba(0, 0, 0, 0.45)' }}>
          <li>
            <p>「注文未確定」または「発送中」の注文のみ更新対象となります</p>
          </li>
          <li>
            <p>AirLogi連携の注文は対象外となります</p>
          </li>
          <li>
            <p>
              条件を満たさない注文情報は取込スキップとなります。取込完了後結果を一覧で参照することが可能です。
            </p>
          </li>
        </ul>
      </Typography.Text>
      <Space size={8} className="full-w flex justify-end">
        <Button
          type="default"
          disabled={isImportLoading}
          onClick={handleCancel}
        >
          キャンセル
        </Button>
        <Button type="primary" loading={isImportLoading} onClick={handleImport}>
          取込
        </Button>
      </Space>
    </Card>
  );
};

/**
 * Main StatusImport Component
 */
const StatusImport = () => {
  const [step, setStep] = useState(1);

  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  const fileList = useSelector(
    (state) => state.orderProvider.statusImport.fileList,
    shallowEqual
  );

  // Redirect if user is not SHIKOMEL supplier or no file is selected
  if (_get(currentUser, 'supplier.type') !== 'SHIKOMEL' || _isEmpty(fileList)) {
    return <Redirect to="/orders" />;
  }

  return (
    <>
      <PageHeader
        title="注文ステータス一括取込"
        breadcrumbRoutes={BREADCRUMB_ITEMS}
      />
      <Card className="bg-transparent" bodyStyle={{ paddingTop: 0 }}>
        {step === 1 && <Step1 setStep={setStep} />}
        {step === 2 && <Step2 />}
      </Card>
    </>
  );
};

export default StatusImport;
