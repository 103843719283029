import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _forEach from 'lodash/forEach';
import _reduce from 'lodash/reduce';
import _map from 'lodash/map';
import _remove from 'lodash/remove';
import _difference from 'lodash/difference';
import axios from 'axios';
import moment from 'moment';
import {
  call, put, takeLatest, takeLeading, select
} from 'redux-saga/effects';

import request from 'utils/request';
import Helpers from 'utils/helpers';
import {
  CLOUD_FUNCTION_PREFIX,
  MESSAGE,
  UTF_8_BOM,
  ORDER_STATUS,
  ORDER_STATUS_JP,
  USER_ROLE,
} from 'utils/constants';
import toastMessage from 'utils/toastMessage';
import {
  GET_ORDER_LIST_REQUEST,
  GET_ORDER_DETAIL_REQUEST,
  UPDATE_ORDER_ITEMS_REQUEST,
  UPDATE_ORDER_STATUS_REQUEST,
  EXPORT_ORDERS_REQUEST,
  GET_ORDER_MESSAGE_LIST_REQUEST,
  CREATE_ORDER_MESSAGE_REQUEST,
  BULK_UPDATE_ORDERS_REQUEST,
  EXPORT_ORDER_WITH_PURCHASE_INFO_REQUEST,
  UPDATE_TRACKING_INFO_REQUEST,
  EXPORT_ORDER_FOR_BRAND_OWNER_REQUEST,
  SELECT_FILE_AIRLOGI_IMPORT_REQUEST,
  IMPORT_FILE_AIRLOGI_REQUEST,
  GET_AIRLOGI_IMPORT_HISTORY_LIST_REQUEST,
  GET_AIRLOGI_IMPORT_HISTORY_DETAIL_REQUEST,
  RE_REGISTER_ORDER_TO_AIR_LOGI_REQUEST,
  UNLINK_ORDER_WITH_AIRLOGI_REQUEST,
  STATUS_IMPORT__SELECT_FILE_REQUEST,
  STATUS_IMPORT__IMPORT_FILE_REQUEST,
  STATUS_IMPORT__GET_HISTORY_LIST_REQUEST,
  STATUS_IMPORT__GET_HISTORY_DETAIL_REQUEST,
} from './constants';
import {
  getOrderListSuccess,
  getOrderListError,
  getOrderDetailRequest,
  getOrderDetailSuccess,
  getOrderDetailError,
  updateOrderItemsSuccess,
  updateOrderItemsError,
  updateOrderStatusSuccess,
  updateOrderStatusError,
  exportOrdersSuccess,
  exportOrdersError,
  getOrderMessageSuccess,
  getOrderMessageError,
  createOrderMessageError,
  createOrderMessageSuccess,
  bulkUpdateOrdersSuccess,
  bulkUpdateOrdersError,
  exportOrdersWithPurchaseInfoSuccess,
  exportOrdersWithPurchaseInfoError,
  updateTrackingInfoSuccess,
  updateTrackingInfoError,
  exportOrderForBrandOwnerSuccess,
  exportOrderForBrandOwnerError,
  selectFileForAirLogiImportSuccess,
  selectFileForAirLogiImportError,
  importFileAirLogiSuccess,
  importFileAirLogiError,
  getAirLogiImportHistoryListSuccess,
  getAirLogiImportHistoryListError,
  getAirLogiImportHistoryDetailSuccess,
  getAirLogiImportHistoryDetailError,
  reRegisterOrderToAirLogiSuccess,
  reRegisterOrderToAirLogiError,
  unlinkOrderWithAirLogiSuccess,
  unlinkOrderWithAirLogiError,
  selectFileForStatusImportSuccess,
  selectFileForStatusImportError,
  importFileStatusSuccess,
  importFileStatusError,
  getStatusImportHistoryListSuccess,
  getStatusImportHistoryListError,
  getStatusImportHistoryDetailSuccess,
  getStatusImportHistoryDetailError,
} from './actions';
import { countUnreadConversationRequest } from '../ConversationProvider/actions';

export function* handleGetOrderList(action) {
  const params = _pick(action.data, [
    'page',
    'limit',
    'orderBy',
    'order',
    'keyword',
    'restaurantId',
    'supplierId',
  ]);
  const createdDateFrom = _get(action.data, 'createdDateFrom');
  const createdDateTo = _get(action.data, 'createdDateTo');

  if (createdDateFrom && createdDateTo) {
    const createdDateFormMoment = moment(createdDateFrom);
    const createdDateToMoment = moment(createdDateTo);
    params.createdDateFrom = createdDateFormMoment
      .startOf('date')
      .toISOString();
    params.createdDateTo = createdDateToMoment.endOf('date').toISOString();
  }

  const deliveryDateFrom = _get(action.data, 'deliveryDateFrom');
  const deliveryDateTo = _get(action.data, 'deliveryDateTo');

  if (deliveryDateFrom && deliveryDateTo) {
    const deliveryDateFormMoment = moment(deliveryDateFrom);
    const deliveryDateToMoment = moment(deliveryDateTo);
    params.deliveryDateFrom = deliveryDateFormMoment
      .startOf('date')
      .toISOString();
    params.deliveryDateTo = deliveryDateToMoment.endOf('date').toISOString();
  }

  const status = _get(action.data, 'status');
  if (status) {
    if (status === ORDER_STATUS.DELIVERED) {
      params.status = ORDER_STATUS.DELIVERED;
      params.fixed = false;
    } else if (status === ORDER_STATUS.FIXED) {
      params.status = ORDER_STATUS.DELIVERED;
      params.fixed = true;
    } else {
      params.status = status;
    }
  }

  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getOrderList`,
      params
    );
    const { result } = response;
    result.list = _forEach(_get(result, 'list', []), (o) => {
      if (
        _get(o, 'status') === ORDER_STATUS.DELIVERED
        && _get(o, 'fixed') === true
      ) {
        o.status = ORDER_STATUS.FIXED;
      }
    });
    yield put(getOrderListSuccess(result));
  } catch (err) {
    yield put(getOrderListError(err));
  }
}

export function* handleGetOrderDetail(action) {
  const { meta, payload } = action;
  const orderId = _get(payload, 'id');
  const params = {
    orderId,
  };

  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getOrderDetail`,
      params
    );
    const orderDetail = response.result;
    if (
      _get(orderDetail, 'status') === ORDER_STATUS.DELIVERED
      && _get(orderDetail, 'fixed') === true
    ) {
      orderDetail.status = ORDER_STATUS.FIXED;
    }
    yield put(getOrderDetailSuccess(response.result, meta));
  } catch (err) {
    yield put(getOrderDetailError(err, meta));
  }
}

export function* handleUpdateOrderItems(action) {
  const { meta } = action;

  const params = {
    ..._pick(action.data, ['orderId']),
    updateAdditionalItems: _get(action, 'data.updateItems'),
    updateMenuItems: _get(action, 'data.updateMenuItems'),
  };

  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateOrderItems`,
      params
    );
    yield put(updateOrderItemsSuccess(response.result, meta));
    yield put(getOrderDetailRequest({ id: params.orderId }));
    toastMessage.success({
      message: '保存しました。',
      description: '変更した内容を保存しました。',
    });
  } catch (error) {
    yield put(updateOrderItemsError(error, meta));
    yield put(getOrderDetailRequest({ id: params.orderId }));
    toastMessage.error({
      description: _get(error.error, 'message', error.error),
    });
  }
}

export function* handleUpdateOrderStatus(action) {
  const { meta } = action;

  const params = _pick(action.data, ['orderId', 'status', 'trackingInfo', 'inHouseDelivering']);

  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateOrderStatus`,
      params
    );
    yield put(updateOrderStatusSuccess(response.result, meta));
    yield put(getOrderDetailRequest({ id: params.orderId }));
    toastMessage.success({ description: MESSAGE.UPDATE_SUCCESS });
  } catch (error) {
    yield put(updateOrderStatusError(error, meta));
    yield put(getOrderDetailRequest({ id: params.orderId }));
    toastMessage.error({
      description: _get(error.error, 'message', error.error),
    });
  }
}

export function* handleExportOrders(action) {
  const { payload, meta } = action;
  const params = _pick(payload, ['statuses', 'format', 'fixedDate']);

  try {
    let result;

    switch (payload.type) {
      case 'AIR_LOGI_ORDER_LIST': {
        result = yield call(request, '/orders/export-airlogi', params);
        break;
      }

      case 'SUMMARY_ORDER_LIST': {
        result = yield call(request, '/orders/export/summary-order', params);
        break;
      }

      default: {
        result = yield call(request, '/orders/export', params);
        break;
      }
    }

    const statuses = params.statuses || Object.values(ORDER_STATUS);
    let fileName = `${process.env.REACT_APP_CSV_PREFIX || ''}${statuses
      .map((status) => {
        let statusStr = ORDER_STATUS_JP[status];
        if (status === ORDER_STATUS.FIXED && params.fixedDate) {
          statusStr += moment(params.fixedDate).format('YYYY年MM月納品');
        }
        return `[${statusStr}]`;
      })
      .join('')}`;
    fileName += `${moment().format('_YYMMDD_HHmm')}.csv`;

    Helpers.saveAsFile(
      result.data,
      fileName,
      'text/csv;charset=utf-8',
      UTF_8_BOM
    );
    yield put(exportOrdersSuccess(MESSAGE.EXPORT_CSV_SUCCESS, meta));
    toastMessage.success({ description: MESSAGE.EXPORT_CSV_SUCCESS });
  } catch (error) {
    yield put(exportOrdersError(error, meta));
    toastMessage.error({ description: error.error || error.message });
  }
}

export function* handleGetOrderMessageList(action) {
  const params = {
    orderId: _get(action.data, 'orderId'),
    page: 1,
    limit: 1000,
  };
  const currentUser = yield select((state) => state.authProvider.currentUser);

  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getOrderMessageList`,
      params
    );
    const { result } = response;
    yield put(getOrderMessageSuccess(_get(result, 'messages', [])));
    if (currentUser?.role === USER_ROLE.SUPPLIER) {
      yield put(countUnreadConversationRequest());
    }
  } catch (error) {
    yield put(getOrderMessageError(error));
    toastMessage.error({
      description: _get(error.error, 'message', error.error),
    });
  }
}

export function* handleCreateOrderMessage(action) {
  const { meta } = action;
  const params = _pick(action.data, ['orderId', 'content']);

  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}createOrderMessage`,
      params
    );
    const { result } = response;
    yield put(createOrderMessageSuccess(result, meta));
  } catch (error) {
    yield put(createOrderMessageError(error, meta));
    toastMessage.error({
      description: _get(error.error, 'message', error.error),
    });
  }
}

export function* handleBulkUpdateOrders(action) {
  const { meta } = action;
  const params = _pick(action.payload, ['orderIds', 'status']);

  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}bulkUpdateOrders`,
      params
    );
    const { result } = response;
    yield put(bulkUpdateOrdersSuccess(result, meta));
  } catch (error) {
    yield put(bulkUpdateOrdersError(error, meta));
    toastMessage.error({ message: 'エラー', description: error.error });
  }
}

export function* handleExportOrdersWithPurchaseInfo(action) {
  const { payload, meta } = action;
  const params = _pick(payload, ['orderIds']);

  try {
    const data = yield call(
      request,
      '/orders/export-orders-purchase-info',
      params,
      { responseType: 'arraybuffer' }
    );
    const fileName = `purchase_orders_${new Date().getTime()}.pdf`;

    Helpers.saveAsFile(data, fileName, 'application/pdf');
    yield put(
      exportOrdersWithPurchaseInfoSuccess(MESSAGE.EXPORT_PDF_SUCCESS, meta)
    );
    toastMessage.success({ description: MESSAGE.EXPORT_PDF_SUCCESS });
  } catch (error) {
    yield put(exportOrdersWithPurchaseInfoError(error, meta));
    toastMessage.error({
      message: 'エラー',
      description: _get(error.error, 'message', error.error),
    });
  }
}

export function* handleUpdateTrackingInfo(action) {
  const { meta } = action;
  const params = _pick(action.payload, ['orderId', 'trackingInfo', 'inHouseDelivering']);

  try {
    const response = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}updateTrackingInfo`,
      params
    );
    yield put(updateTrackingInfoSuccess(response.result, meta));
    yield put(getOrderDetailRequest({ id: params.orderId }));
    toastMessage.success({ description: MESSAGE.UPDATE_SUCCESS });
  } catch (error) {
    yield put(updateTrackingInfoError(error, meta));
    toastMessage.error({
      description: _get(error.error, 'message', error.error),
    });
  }
}

export function* handleExportOrderForBrandOwner(action) {
  const { payload, meta } = action;
  const params = _pick(payload, ['brandId', 'supplierId']);
  const createdDateFrom = _get(payload, 'createdDateFrom');
  const createdDateTo = _get(payload, 'createdDateTo');

  if (createdDateFrom && createdDateTo) {
    const createdDateFormMoment = moment(createdDateFrom);
    const createdDateToMoment = moment(createdDateTo);
    params.createdDateFrom = createdDateFormMoment
      .startOf('date')
      .toISOString();
    params.createdDateTo = createdDateToMoment.endOf('date').toISOString();
  }

  const deliveryDateFrom = _get(payload, 'deliveryDateFrom');
  const deliveryDateTo = _get(payload, 'deliveryDateTo');

  if (deliveryDateFrom && deliveryDateTo) {
    const deliveryDateFormMoment = moment(deliveryDateFrom);
    const deliveryDateToMoment = moment(deliveryDateTo);
    params.deliveryDateFrom = deliveryDateFormMoment
      .startOf('date')
      .toISOString();
    params.deliveryDateTo = deliveryDateToMoment.endOf('date').toISOString();
  }

  try {
    const result = yield call(request, '/orders/export-for-brand-owner', params);

    const fileName = `${process.env.REACT_APP_CSV_PREFIX || ''}${moment().format('YYMMDD_HHmm')}.csv`;

    Helpers.saveAsFile(result.data, fileName, 'text/csv;charset=utf-8', UTF_8_BOM);
    yield put(exportOrderForBrandOwnerSuccess(MESSAGE.EXPORT_CSV_SUCCESS, meta));
    toastMessage.success({ description: MESSAGE.EXPORT_CSV_SUCCESS });
  } catch (error) {
    yield put(exportOrderForBrandOwnerError(error, meta));
    toastMessage.error({ description: error.error || error.message });
  }
}

export function* handleSelectFileAirLogiImport(action) {
  const { meta } = action;
  const { data } = action.payload;

  try {
    data[0][0] = 'orderId';
    data[0][1] = 'trackingIds';

    const trackingIdsPerOrderId = _reduce(
      Helpers.convertToArrayOfObjects(data),
      (pre, cur) => {
        const orderId = cur?.orderId?.substr(1);
        if (!pre[orderId]) {
          pre[orderId] = [];
        }
        const trackingIds = cur.trackingIds?.split(',');
        _remove(trackingIds, (s) => s?.trim() === '');
        const newTrackingIds = _difference(trackingIds, pre[orderId]);
        pre[orderId].push(...newTrackingIds);
        return pre;
      },
      {}
    );

    yield put(selectFileForAirLogiImportSuccess(trackingIdsPerOrderId, meta));
  } catch (error) {
    yield put(selectFileForAirLogiImportError(error, meta));
  }
}

export function* handleImportFileAirLogi(action) {
  const { meta, payload } = action;
  let fileId;

  try {
    if (payload.file) {
      const { result: fileUploadResult } = yield call(
        request,
        `${CLOUD_FUNCTION_PREFIX}generateUploadFileUrl`,
        {
          fileName: _get(payload, 'file.name'),
          fileType: _get(payload, 'file.type'),
          type: 'AIR_LOGI_IMPORT',
        }
      );

      fileId = _get(fileUploadResult, 'objectId');

      yield call(() => axios.put(fileUploadResult.signedUrl, payload.file, {
        headers: {
          'Content-Type': payload.file.type,
        },
      })
      );
    }

    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}importTrackingIdsFromAirLogi`,
      {
        fileId,
        ordersData: _map(
          Object.keys(payload.trackingIdsPerOrderId),
          (orderId) => ({
            orderId,
            trackingIds: payload.trackingIdsPerOrderId[orderId],
          })
        ),
      }
    );

    yield put(importFileAirLogiSuccess({ ...result, objectId: fileId }, meta));
  } catch (error) {
    yield put(importFileAirLogiError(error, meta));
  }
}

export function* handleGetAirLogiImportHistoryList(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getAirLogiImportHistoryList`,
      payload
    );
    yield put(getAirLogiImportHistoryListSuccess(result, meta));
  } catch (error) {
    yield put(getAirLogiImportHistoryListError(error, meta));
  }
}
export function* handleGetAirLogiImportHistoryDetail(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getAirLogiImportHistoryDetail`,
      payload
    );
    yield put(getAirLogiImportHistoryDetailSuccess(result, meta));
  } catch (error) {
    yield put(getAirLogiImportHistoryDetailError(error, meta));
  }
}
export function* handleReRegisterOrderToAirLogi(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}reRegisterOrderToAirLogi`,
      payload
    );
    yield put(reRegisterOrderToAirLogiSuccess(result, meta));
    toastMessage.success({ description: MESSAGE.UPDATE_SUCCESS });
  } catch (error) {
    yield put(reRegisterOrderToAirLogiError(error, meta));
    toastMessage.error({
      message: 'エラー',
      description: _get(error.error, 'message', error.error),
    });
  }
}
export function* handleUnlinkOrderWithAirLogi(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}unlinkOrderWithAirLogi`,
      payload
    );
    yield put(unlinkOrderWithAirLogiSuccess(result, meta));
  } catch (error) {
    yield put(unlinkOrderWithAirLogiError(error, meta));
    toastMessage.error({
      message: 'エラー',
      description: _get(error.error, 'message', error.error),
    });
  }
}

export function* handleStatusImportSelectFile(action) {
  const { data } = action.payload;

  try {
    data[0][0] = 'orderId';
    data[0][1] = 'status';
    yield put(
      selectFileForStatusImportSuccess(
        Helpers.convertToArrayOfObjects(data, ['orderId', 'status']),
        action?.meta
      )
    );
  } catch (error) {
    yield put(selectFileForStatusImportError(error, action?.meta));
    toastMessage.error({
      description: _get(error.error, 'message', error.error),
    });
  }
}

export function* handleStatusImportImportFile(action) {
  const { meta, payload } = action;
  let fileId;

  try {
    if (payload.file) {
      const { result: fileUploadResult } = yield call(
        request,
        `${CLOUD_FUNCTION_PREFIX}generateUploadFileUrl`,
        {
          fileName: _get(payload, 'file.name'),
          fileType: _get(payload, 'file.type'),
          type: 'ORDER_STATUS_IMPORT',
        }
      );

      fileId = _get(fileUploadResult, 'objectId');

      yield call(() => axios.put(fileUploadResult.signedUrl, payload.file, {
        headers: {
          'Content-Type': payload.file.type,
        },
      })
      );
    }

    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}importOrderStatus`,
      {
        fileId,
        ordersData: payload.importData,
      }
    );

    yield put(importFileStatusSuccess({ ...result, objectId: fileId }, meta));
  } catch (error) {
    yield put(importFileStatusError(error, meta));
    toastMessage.error({
      description: _get(error.error, 'message', error.error),
    });
  }
}

export function* handleStatusImportGetHistoryList(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getOrderStatusImportHistoryList`,
      payload
    );
    yield put(getStatusImportHistoryListSuccess(result, meta));
  } catch (error) {
    yield put(getStatusImportHistoryListError(error, meta));
    toastMessage.error({
      description: _get(error.error, 'message', error.error),
    });
  }
}

export function* handleStatusImportGetHistoryDetail(action) {
  const { meta, payload } = action;
  try {
    const { result } = yield call(
      request,
      `${CLOUD_FUNCTION_PREFIX}getOrderStatusImportHistoryDetail`,
      payload
    );
    yield put(getStatusImportHistoryDetailSuccess(result, meta));
  } catch (error) {
    yield put(getStatusImportHistoryDetailError(error, meta));
    toastMessage.error({
      description: _get(error.error, 'message', error.error),
    });
  }
}

export default function* orderPageSaga() {
  yield takeLatest(GET_ORDER_LIST_REQUEST, handleGetOrderList);
  yield takeLatest(GET_ORDER_DETAIL_REQUEST, handleGetOrderDetail);
  yield takeLeading(UPDATE_ORDER_ITEMS_REQUEST, handleUpdateOrderItems);
  yield takeLeading(UPDATE_ORDER_STATUS_REQUEST, handleUpdateOrderStatus);
  yield takeLeading(EXPORT_ORDERS_REQUEST, handleExportOrders);
  yield takeLatest(GET_ORDER_MESSAGE_LIST_REQUEST, handleGetOrderMessageList);
  yield takeLeading(CREATE_ORDER_MESSAGE_REQUEST, handleCreateOrderMessage);
  yield takeLeading(BULK_UPDATE_ORDERS_REQUEST, handleBulkUpdateOrders);
  yield takeLeading(EXPORT_ORDER_WITH_PURCHASE_INFO_REQUEST, handleExportOrdersWithPurchaseInfo);
  yield takeLeading(UPDATE_TRACKING_INFO_REQUEST, handleUpdateTrackingInfo);
  yield takeLeading(EXPORT_ORDER_FOR_BRAND_OWNER_REQUEST, handleExportOrderForBrandOwner);
  yield takeLeading(SELECT_FILE_AIRLOGI_IMPORT_REQUEST, handleSelectFileAirLogiImport);
  yield takeLeading(IMPORT_FILE_AIRLOGI_REQUEST, handleImportFileAirLogi);
  yield takeLatest(GET_AIRLOGI_IMPORT_HISTORY_LIST_REQUEST, handleGetAirLogiImportHistoryList);
  yield takeLatest(GET_AIRLOGI_IMPORT_HISTORY_DETAIL_REQUEST, handleGetAirLogiImportHistoryDetail);
  yield takeLatest(RE_REGISTER_ORDER_TO_AIR_LOGI_REQUEST, handleReRegisterOrderToAirLogi);
  yield takeLeading(UNLINK_ORDER_WITH_AIRLOGI_REQUEST, handleUnlinkOrderWithAirLogi);
  yield takeLeading(STATUS_IMPORT__SELECT_FILE_REQUEST, handleStatusImportSelectFile);
  yield takeLeading(STATUS_IMPORT__IMPORT_FILE_REQUEST, handleStatusImportImportFile);
  yield takeLatest(STATUS_IMPORT__GET_HISTORY_LIST_REQUEST, handleStatusImportGetHistoryList);
  yield takeLatest(STATUS_IMPORT__GET_HISTORY_DETAIL_REQUEST, handleStatusImportGetHistoryDetail);
}
