import _get from 'lodash/get';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Redirect } from 'react-router-dom';
import { Card } from 'antd';

import Table from 'components/Table';
import PageHeader from 'components/PageHeader';
import useTableControl from 'components/Hooks/useTableControl';

import { getStatusImportHistoryListRequest } from 'providers/OrderProvider/actions';

import { DATE_TIME_FORMAT } from 'utils/constants';

// Custom hook to handle status import history data
const useStatusImportHistory = () => {
  const dispatch = useDispatch();
  const [tableLoading, setTableLoading] = useState(false);
  const [pageControl, filterControl, onTableChange] = useTableControl();
  const { page, limit } = pageControl;

  const statusImportHistoryList = useSelector(
    (state) => state.orderProvider.statusImportHistoryList,
    shallowEqual
  );

  const fetchStatusImportHistoryList = useCallback(async () => {
    setTableLoading(true);
    try {
      await dispatch(getStatusImportHistoryListRequest({ page, limit }));
    } catch (error) {
      console.error('Failed to fetch status import history:', error);
    } finally {
      setTableLoading(false);
    }
  }, [page, limit, dispatch]);

  useEffect(() => {
    fetchStatusImportHistoryList();
  }, [fetchStatusImportHistoryList]);

  return {
    statusImportHistoryList,
    tableLoading,
    pageControl,
    filterControl,
    onTableChange,
  };
};

const StatusImportHistory = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  // Check if user has permission to access this page
  if (_get(currentUser, 'supplier.type') !== 'SHIKOMEL') {
    return <Redirect to="/orders" />;
  }

  const {
    statusImportHistoryList,
    tableLoading,
    pageControl: { page, limit },
    onTableChange,
  } = useStatusImportHistory();

  // Table columns definition
  const columns = [
    {
      title: 'Date time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => moment(value).format(DATE_TIME_FORMAT),
    },
    // Additional columns can be added here as needed
  ];

  // Breadcrumb configuration
  const breadcrumbItems = [
    {
      path: '/orders',
      breadcrumbName: '注文一覧',
    },
    {
      breadcrumbName: '注文ステータス一括取込履歴',
    },
  ];

  const handleRowClick = (record) => {
    dispatch(
      push(`/orders/status-imports/history/${record.objectId}?stt=SUCCESS`)
    );
  };

  return (
    <div className="status-import-history-list">
      <PageHeader
        breadcrumbRoutes={breadcrumbItems}
        onBack={() => dispatch(push('/orders'))}
        title="注文ステータス一括取込履歴"
      />
      <Card
        className="bg-transparent"
        bodyStyle={{ paddingTop: 0 }}
        bordered={false}
      >
        <Card bordered={false} className="highlight-card padding-card">
          <Table
            onRowClick={handleRowClick}
            columns={columns}
            data={statusImportHistoryList?.list}
            total={statusImportHistoryList?.total}
            loading={tableLoading}
            onChange={onTableChange}
            pagination={{
              current: Number(page),
              pageSize: Number(limit),
              marginRight: true,
            }}
          />
        </Card>
      </Card>
    </div>
  );
};

export default StatusImportHistory;
