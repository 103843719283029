import moment from 'moment';
import _map from 'lodash/map';
import _get from 'lodash/get';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { replace } from 'connected-react-router';
import {
  Card, Tabs, Tag, Skeleton
} from 'antd';
import PropTypes from 'prop-types';

import { DATE_TIME_FORMAT } from 'utils/constants';
import { getStatusImportHistoryDetailRequest } from 'providers/OrderProvider/actions';
import Table from 'components/Table';
import PageHeader from 'components/PageHeader';
import useTableControl from 'components/Hooks/useTableControl';

import './style.less';

const { TabPane } = Tabs;

// Constants
const ORDER_STATUS = {
  SUCCESS: '成功件数',
  ERROR: 'スキップ件数',
};

const STATUS_TO_TEXT = {
  ERROR__NOT_FOUND: '注文番号が存在しません。', // Error: Order not found
  ERROR__AIR_LOGI_INTEGRATED: 'AirLogiに連携している注文のためステータス変更できません', // Error: Order is integrated with AirLogi
  ERROR__INVALID_TARGET_STATUS: '指定されたステータスが「注文受領」「配送完了」のいずれでもないため、変更できません。', // Error: Target status is not Processing or Delivered
  ERROR__INVALID_STATUS_TO_PROCESSING: 'ステータスが「注文未確定」ではないため、「注文受領」に変更できません。', // Error: Status is not Submitted so can not change to Processing
  ERROR__INVALID_STATUS_TO_DELIVERED: 'ステータスが「発送中」ではないため、「配送完了」に変更できません。', // Error: Status is not Delivering so can not change to Delivered
  ERROR__SOMETHING_WENT_WRONG: 'その他', // Error: Other error

  SUCCESS__TO_DELIVERED: '配送完了',
  SUCCESS__TO_PROCESSING: '注文受領',
  SUCCESS__TO_PAYMENT_FAILED: '決済エラー',
};

const STATUS_TAG_COLORS = {
  SUCCESS__TO_DELIVERED: '#399E0E',
  SUCCESS__TO_PROCESSING: '#ee780f',
  SUCCESS__TO_PAYMENT_FAILED: '#cf1421',
};

const SUPPLIER_TYPE_SHIKOMEL = 'SHIKOMEL';

// Table columns configurations
const getSuccessColumns = () => [
  { title: '注文番号', dataIndex: ['order', 'objectId'], key: 'orderId' },
  {
    title: '取込後のステータス',
    dataIndex: 'status',
    key: 'status',
    render: (status) => (
      <StatusTag
        color={STATUS_TAG_COLORS[status]}
        status={STATUS_TO_TEXT[status] || 'その他'}
      />
    ),
  },
];

const getErrorColumns = () => [
  {
    title: '注文番号',
    dataIndex: 'importedOrderId',
    key: 'importedOrderId',
  },
  {
    title: 'スキップ理由',
    dataIndex: 'status',
    key: 'status',
    render: (status) => STATUS_TO_TEXT[status] || 'その他',
  },
];

// Component for rendering status tags consistently
const StatusTag = ({ status, color }) => (
  <div style={{ width: 'fit-content' }}>
    <Tag color={color}>{status}</Tag>
  </div>
);

StatusTag.propTypes = {
  color: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

// Component for rendering status tabs
const StatusTabs = ({
  status,
  countFilteredOrderPerStatus,
  onStatusTabsChange,
  loading,
}) => {
  // Render the count for each status tab
  const renderStatusCount = (key) => {
    if (loading) {
      return (
        <Skeleton.Button
          active
          size="small"
          style={{ width: '20px', height: '14px' }}
        />
      );
    }

    if (countFilteredOrderPerStatus) {
      return countFilteredOrderPerStatus[key] || 0;
    }

    return 0;
  };

  return (
    <Tabs defaultActiveKey={status} onChange={onStatusTabsChange}>
      {_map(ORDER_STATUS, (value, key) => (
        <TabPane
          tab={(
            <>
              <div id="count-filter-status">{renderStatusCount(key)}</div>
              <div id="count-filter-status-text">{value}</div>
            </>
          )}
          key={key}
        />
      ))}
    </Tabs>
  );
};

StatusTabs.propTypes = {
  status: PropTypes.string.isRequired,
  countFilteredOrderPerStatus: PropTypes.object,
  onStatusTabsChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

StatusTabs.defaultProps = {
  loading: false,
};

const StatusHistoryDetail = () => {
  const dispatch = useDispatch();
  const { importHistoryId } = useParams();
  const [tableLoading, setTableLoading] = useState(false);

  // Redux selectors
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  const statusImportHistoryDetail = useSelector(
    (state) => state.orderProvider.statusImportHistoryDetail,
    shallowEqual
  );

  // Access control check
  if (_get(currentUser, 'supplier.type') !== SUPPLIER_TYPE_SHIKOMEL) {
    return <Redirect to="/orders" />;
  }

  // Table control hooks
  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({
    filterKeys: {
      status: 'stt',
    },
  });

  const { page, limit } = pageControl;
  const { status } = filterControl;

  // Derived data
  const countFilteredOrderPerStatus = _get(
    statusImportHistoryDetail,
    'countResult'
  );

  const createdAt = statusImportHistoryDetail?.createdAt
    ? moment(statusImportHistoryDetail.createdAt).format(DATE_TIME_FORMAT)
    : '';

  // Data fetching
  const fetchStatusImportHistoryDetail = useCallback(async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getStatusImportHistoryDetailRequest({
          page,
          limit,
          status,
          importHistoryId,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  }, [page, limit, status, importHistoryId, dispatch]);

  useEffect(() => {
    fetchStatusImportHistoryDetail();
  }, [fetchStatusImportHistoryDetail]);

  // Event handlers
  const onStatusTabsChange = (newStatus) => {
    onFilterChange({
      status: newStatus,
    });
  };

  const handleBack = () => {
    dispatch(replace('/orders/status-imports/history'));
  };

  // Breadcrumb configuration
  const breadcrumbItems = [
    {
      path: '/orders',
      breadcrumbName: '注文一覧',
    },
    {
      path: '/status-imports/history',
      breadcrumbName: '注文ステータス一括取込',
    },
    {
      breadcrumbName: tableLoading ? '...' : createdAt,
    },
  ];

  // Column selection based on tab
  const columns = status === 'SUCCESS' ? getSuccessColumns() : getErrorColumns();

  // Render page title with loading state
  const renderPageTitle = () => {
    if (tableLoading) {
      return <Skeleton.Input active style={{ width: 200 }} />;
    }
    return createdAt;
  };

  return (
    <div id="status-import-history-detail">
      <PageHeader
        breadcrumbRoutes={breadcrumbItems}
        title={renderPageTitle()}
        onBack={handleBack}
      />
      <Card
        border={false}
        className="bg-transparent"
        bodyStyle={{
          paddingTop: 0,
        }}
      >
        <Card
          bordered={false}
          className="highlight-card padding-card"
          title="ステータス変更結果"
          headStyle={{ fontSize: 24, fontWeight: 'bold', padding: '0px 16px' }}
        >
          <StatusTabs
            status={status}
            countFilteredOrderPerStatus={countFilteredOrderPerStatus}
            onStatusTabsChange={onStatusTabsChange}
            loading={tableLoading}
          />
          <Table
            columns={columns}
            data={statusImportHistoryDetail?.list}
            total={statusImportHistoryDetail?.total}
            loading={tableLoading}
            onChange={onTableChange}
            pagination={{
              current: Number(page),
              pageSize: Number(limit),
            }}
          />
        </Card>
      </Card>
    </div>
  );
};

export default StatusHistoryDetail;
