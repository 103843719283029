import _get from 'lodash/get';
import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { DownloadOutlined } from '@ant-design/icons';

import {
  Card, Input, Tag, Button, Space
} from 'antd';
import Table from 'components/Table';

import { ORDER_STATUS_JP, ORDER_STATUS_COLOR } from 'utils/constants';
import { exportOrderForBrandOwnerRequest } from 'providers/OrderProvider/actions';

import Columns from './Columns';

const { Search } = Input;

const BrandOwnerOrderList = (props) => {
  const {
    onFilterChange,
    filterControl,
    pageControl,
    onTableChange,
    tableLoading,
  } = props;

  const dispatch = useDispatch();
  const [exportLoading, setExportLoading] = useState(false);

  const orderListForBrandOwner = useSelector(
    (state) => state.brandOwnerProvider.orderListForBrandOwner,
    shallowEqual
  );
  const { page, limit } = pageControl;
  const {
    keyword,
    deliveryDateFrom,
    deliveryDateTo,
    createdDateFrom,
    createdDateTo,
    supplierId,
    brandId,
  } = filterControl;

  const columns = [
    Columns.ID,
    Columns.restaurantInfo,
    Columns.supplierInfo,
    Columns.numberOfProduct,
    Columns.totalBrandFee,
    Columns.orderDate,
    Columns.deliveryDate,
    {
      ...Columns.status,
      render: (record) => (
        <Tag color={ORDER_STATUS_COLOR[record.status]}>
          {ORDER_STATUS_JP[record.status]}
        </Tag>
      ),
    },
  ];

  const handleExport = async () => {
    setExportLoading(true);
    try {
      await dispatch(
        exportOrderForBrandOwnerRequest({
          deliveryDateFrom,
          deliveryDateTo,
          createdDateFrom,
          createdDateTo,
          supplierId,
          brandId,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setExportLoading(false);
  };

  return (
    <Card className="highlight-card padding-card" bordered={false}>
      <div className="items-center padding-16 border-bottom">
        <Space className="flex items-center flex-wrap justify-between">
          <Search
            placeholder="IDで検索"
            defaultValue={keyword}
            onSearch={(val) => onFilterChange({ keyword: val })}
            style={{ width: 200 }}
            className="search-input"
          />
          <div style={{ flexGrow: 1 }} />
          <Button
            icon={<DownloadOutlined />}
            type="primary"
            loading={exportLoading}
            onClick={handleExport}
          >
            注文明細DL
          </Button>
        </Space>
      </div>
      <Table
        columns={columns}
        data={_get(orderListForBrandOwner, 'list', []).map((o) => ({
          ...o,
          key: o.objectId,
        }))}
        onRowClick={(record) => {
          dispatch(push(`/orders/detail/${record.objectId}`));
        }}
        total={_get(orderListForBrandOwner, 'total', 0)}
        loading={tableLoading}
        onChange={onTableChange}
        pagination={{
          current: Number(page),
          pageSize: Number(limit),
        }}
        scroll={{ x: 1120 }}
      />
    </Card>
  );
};

BrandOwnerOrderList.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  filterControl: PropTypes.func,
  pageControl: PropTypes.func,
  onTableChange: PropTypes.func,
  tableLoading: PropTypes.bool,
};

export default BrandOwnerOrderList;
