import _get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  Card, Space, Input, Tabs, Typography
} from 'antd';
import withRefresh from 'components/HOCs/withRefresh';
import useTableControl from 'components/Hooks/useTableControl';
import Table from 'components/Table';

import {
  DATE_TIME_FORMAT,
  ORDER_STATUS,
  ORDER_STATUS_JP,
  USER_ROLE,
} from 'utils/constants';

import Columns from './Columns';

import BulkActions from './BulkActions';
import OrderListSearchForm from './OrderListSearchForm';

import './style.scss';
import AirLogiImportDropdown from './AirLogiImportDropdown';
import StatusImportDropdown from './StatusImportDropdown';
import CSVExportButton from './CSVExportButton';

const { Search } = Input;
const { TabPane } = Tabs;

const supplierType = 'SHIKOMEL';

const OrderList = (props) => {
  const {
    orderList, loading, getOrderListRequest, push
  } = props;

  const [selectedOrderIds, setSelectedOrderIds] = useState([]);

  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  const userRole = currentUser.role;
  const columnList = Columns(userRole);

  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({
    filterKeys: {
      keyword: 'kw',
      deliveryDateFrom: 'df',
      deliveryDateTo: 'dt',
      createdDateFrom: 'cf',
      createdDateTo: 'ct',
      status: 'stt',
      restaurantId: 'ri',
      supplierId: 'si',
    },
  });

  const {
    page, limit, orderBy, order
  } = pageControl;

  const {
    keyword,
    deliveryDateFrom,
    deliveryDateTo,
    createdDateFrom,
    createdDateTo,
    status,
    restaurantId,
    supplierId,
  } = filterControl;

  useEffect(() => {
    getOrderListRequest({
      page,
      limit,
      orderBy,
      order,
      keyword,
      status,
      deliveryDateFrom,
      deliveryDateTo,
      createdDateFrom,
      createdDateTo,
      restaurantId,
      supplierId,
    });
  }, [
    page,
    limit,
    orderBy,
    order,
    keyword,
    status,
    deliveryDateFrom,
    deliveryDateTo,
    createdDateFrom,
    createdDateTo,
    restaurantId,
    supplierId,
  ]);

  const onStatusTabsChange = (evtStatus) => {
    onFilterChange({
      status: evtStatus || '',
    });
    setSelectedOrderIds([]);
  };

  const onFilter = (changedValues, allValues) => {
    let strDeliveryDateForm;
    let strDeliveryDateTo;
    let strCreatedDateFrom;
    let strCreatedDateTo;

    if (allValues.createdDate) {
      strCreatedDateFrom = allValues.createdDate[0]
        && moment(allValues.createdDate[0]).toISOString();
      strCreatedDateTo = allValues.createdDate[1]
        && moment(allValues.createdDate[1]).toISOString();
    }
    if (allValues.deliveryDate) {
      strDeliveryDateForm = allValues.deliveryDate[0]
        && moment(allValues.deliveryDate[0]).toISOString();
      strDeliveryDateTo = allValues.deliveryDate[1]
        && moment(allValues.deliveryDate[1]).toISOString();
    }

    onFilterChange({
      deliveryDateFrom: strDeliveryDateForm,
      deliveryDateTo: strDeliveryDateTo,
      createdDateFrom: strCreatedDateFrom,
      createdDateTo: strCreatedDateTo,
      supplierId: allValues.supplierId,
      restaurantId: allValues.restaurantId,
    });
  };

  const countFilteredOrderPerStatus = _get(
    orderList,
    'countFilteredOrderPerStatus'
  );

  const rowSelection = {
    onChange: (ids) => {
      setSelectedOrderIds(ids);
    },
    selectedRowKeys: selectedOrderIds,
    renderCell: (checked, record, index, originNode) => (
      <div
        className="custom-render-cell"
        onClick={(e) => e.stopPropagation()}
        aria-hidden="true"
      >
        {originNode}
      </div>
    ),
  };

  const columns = [
    columnList.icon,
    {
      ...columnList.restaurantInfo,
      sortOrder: orderBy === 'restaurantName' && order,
      sorter: true,
    },
    {
      ...columnList.ID,
      sortOrder: orderBy === 'objectId' && order,
      sorter: true,
    },
    {
      ...columnList.createdAt,
      sortOrder: orderBy === 'createdAt' && order,
      sorter: true,
    },
    columnList.trackingInfo,
    columnList.deliveryPrefecture,
    {
      ...columnList.deliveryDate,
      sortOrder: orderBy === 'deliveryDate' && order,
      sorter: true,
    },
    columnList.totalPrice,
    columnList.status,
  ];

  const isShikomelSupplier = _get(currentUser, 'supplier.type') === supplierType;

  if (isShikomelSupplier) {
    columns.splice(2, 0, columnList.parentId);
  }

  const lastUpdated = isShikomelSupplier && (
    <Typography.Text
      type="secondary"
      style={{
        width: 210
      }}
    >
      最終更新日時:{' '}
      {moment(_get(orderList, 'airLogi_lastSyncingTime')).format(
        DATE_TIME_FORMAT
      )}
    </Typography.Text>
  );

  return (
    <Card
      id="order-list-container"
      className="bg-transparent"
      bordered={false}
    >
      <Space className="full-w" direction="vertical" size="middle">
        <Card className="highlight-card padding-card-16" bordered={false}>
          <OrderListSearchForm
            filterControl={filterControl}
            onFilter={onFilter}
            onFilterChange={onFilterChange}
          />
        </Card>
        <Card
          className="highlight-card order-status-container"
          bordered={false}
        >
          <div className="flex justify-between items-center">
            <Tabs
              defaultActiveKey={status}
              onChange={onStatusTabsChange}
              style={{
                width: '60%',
                flex: 1,
              }}
            >
              <TabPane
                tab={(
                  <>
                    <div id="count-filter-status">
                      {countFilteredOrderPerStatus
                          && (countFilteredOrderPerStatus.ALL || 0)}
                    </div>
                    <div>全て</div>
                  </>
                )}
                key=""
              />
              {Object.keys(ORDER_STATUS).map((option) => (
                <TabPane
                  tab={(
                    <>
                      <div id="count-filter-status">
                        {countFilteredOrderPerStatus
                            && (countFilteredOrderPerStatus[option] || 0)}
                      </div>
                      <div>
                        {option === 'PAYMENT_FAILED'
                          ? 'エラー'
                          : ORDER_STATUS_JP[option]}
                      </div>
                    </>
                  )}
                  key={option}
                />
              ))}
            </Tabs>
            {lastUpdated}
          </div>
        </Card>
        <Card className="highlight-card padding-card" bordered={false}>
          <div className="items-center padding-16 border-bottom">
            <Space size={8} className="flex flex-wrap justify-between">
              <Search
                placeholder="ID/店舗名で検索"
                className="search-input"
                defaultValue={keyword}
                onSearch={(val) => onFilterChange({ keyword: val })}
                style={{ width: 200 }}
              />
              <div style={{ flexGrow: 1 }} />
              {userRole !== USER_ROLE.OPERATOR
                  && (selectedOrderIds.length > 0 ? (
                    <BulkActions selectedOrder={{ selectedOrderIds, status }} />
                  ) : (
                    <Space size={8} className="flex flex-wrap">
                      <AirLogiImportDropdown />
                      <StatusImportDropdown />
                      <CSVExportButton />
                    </Space>
                  ))}
            </Space>
          </div>
          <Table
            rowSelection={userRole === USER_ROLE.SUPPLIER && rowSelection}
            onRowClick={(record) => {
              push(`/orders/detail/${record.objectId}`);
            }}
            columns={columns}
            data={_get(orderList, 'list', [])}
            total={_get(orderList, 'total', 0)}
            loading={loading}
            onChange={onTableChange}
            pagination={{
              current: Number(page),
              pageSize: Number(limit),
            }}
            scroll={{ x: 1330 }}
          />
        </Card>
      </Space>
    </Card>
  );
};

OrderList.propTypes = {
  getOrderListRequest: PropTypes.func,
  orderList: PropTypes.object,
  push: PropTypes.func,
  loading: PropTypes.bool,
};

export default withRefresh('orderList')(OrderList);
