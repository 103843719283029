import _toString from 'lodash/toString';
import _last from 'lodash/last';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import cardValidator from 'card-validator';
import {
  WAREHOUSE_MENU_SYNC_STATUS,
  CLOUD_FUNCTION_PREFIX,
} from 'utils/constants';
import {
  call
} from 'redux-saga/effects';
import axios from 'axios';
import request, { getBlob } from './request';

const hexOctet = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
const generateInstallationId = () => `${hexOctet() + hexOctet()}-${hexOctet()}-${hexOctet()}-${hexOctet()}-${hexOctet()}${hexOctet()}${hexOctet()}`;
const currencyFormat = new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' });
const fileSave = (blob, fileName) => {
  const a = document.createElement('a');
  const url = URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};

export default {
  priceFormat: (num) => currencyFormat.format(num),
  numberWithCommas: (x) => _toString(x).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  getInstallationId: () => {
    const installationId = localStorage.getItem('installationId');
    if (!installationId) {
      const generatedInstallationId = generateInstallationId();
      localStorage.setItem('installationId', generatedInstallationId);
      return generatedInstallationId;
    }
    return installationId;
  },
  saveAsFile: (data, fileName, type, dataPrefix = '') => {
    const blobArray = dataPrefix ? [dataPrefix, data] : [data];
    const file = new Blob(blobArray, { type });
    if (window.navigator.msSaveOrOpenBlob) {
      // IE10+
      window.navigator.msSaveOrOpenBlob(file, fileName);
    } else {
      fileSave(file, fileName);
    }
  },
  downloadFile: async (url, fileName) => {
    const blob = await getBlob(url);
    fileSave(blob, fileName);
  },
  normalizeZipcode: (value, prevValue) => {
    if (!value) {
      return value;
    }

    const onlyNums = value.replace(/[^\d]/g, '');
    if (onlyNums.length > 7) {
      return prevValue;
    }
    return onlyNums;
  },
  isBasicItemPath: (pathname) => {
    const pathKeys = pathname ? pathname.split('/') : [];
    if (_last(pathKeys) === 'basic-items') {
      return true;
    }
    return false;
  },
  generateObjectId: () => `${hexOctet() + hexOctet()}`,
  getCardType: (cardNumber) => {
    if (typeof cardNumber !== 'string') {
      return '';
    }
    const cardNumberValue = cardNumber.slice(0, 6);
    const cardInfo = cardValidator.number(cardNumberValue);
    return _get(cardInfo, 'card.niceType');
  },
  convertToArrayOfObjects: (data, selectFields) => {
    const keys = data.shift();
    let i = 0;
    let k = 0;
    let obj = null;
    const output = [];
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < data.length; i++) {
      obj = {};
      // eslint-disable-next-line no-plusplus
      for (k = 0; k < keys.length; k++) {
        obj[keys[k]] = data[i][k];
      }
      if (!selectFields) {
        output.push(obj);
      } else {
        output.push(_pick(obj, selectFields));
      }
    }
    return output;
  },
  getSyncStatus: (warehouseMenus) => {
    let status;

    // eslint-disable-next-line no-restricted-syntax
    for (const cur of WAREHOUSE_MENU_SYNC_STATUS) {
      if (
        _find(warehouseMenus, (o) => cur.keys.includes(o.airLogi_syncStatus))
      ) {
        status = cur;
        break;
      }
    }
    return status;
  },
  getUploadUrlList: (fileList, url, type) => _map(fileList, (file) => call(request, `${CLOUD_FUNCTION_PREFIX}${url}`, {
    fileName: _get(file, 'originFileObj.name'),
    fileType: _get(file, 'originFileObj.type'),
    type,
  })),
  uploadFileList: (UrlList, fileList) => _map(UrlList, ({ result }, index) => call(() => axios.put(
    result.signedUrl,
    _get(fileList[index], 'originFileObj'),
    {
      headers: {
        'Content-Type': _get(
          fileList[index],
          'originFileObj.type'
        ),
      },
    }
  )))
};
