import _get from 'lodash/get';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Redirect } from 'react-router-dom';
import { Card } from 'antd';

import Table from 'components/Table';
import PageHeader from 'components/PageHeader';
import useTableControl from 'components/Hooks/useTableControl';

import { getAirLogiImportHistoryListRequest } from 'providers/OrderProvider/actions';

import { DATE_TIME_FORMAT } from 'utils/constants';

const AirLogiHistory = () => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  if (_get(currentUser, 'supplier.type') !== 'SHIKOMEL') {
    return <Redirect to="/orders" />;
  }

  const dispatch = useDispatch();
  const airLogiImportHistoryList = useSelector(
    (state) => state.orderProvider.airLogiImportHistoryList,
    shallowEqual
  );

  const [tableLoading, setTableLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [pageControl, filterControl, onTableChange] = useTableControl();
  const { page, limit } = pageControl;

  const fetchAirLogiImportHistoryList = useCallback(async () => {
    setTableLoading(true);
    try {
      await dispatch(getAirLogiImportHistoryListRequest({ page, limit }));
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  }, [page, limit, dispatch]);

  useEffect(() => {
    fetchAirLogiImportHistoryList();
  }, [fetchAirLogiImportHistoryList]);

  const columns = [
    {
      title: 'Date time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (record) => moment(record).format(DATE_TIME_FORMAT),
    },
  ];

  const breadcrumbItems = [
    {
      path: '/orders',
      breadcrumbName: '注文一覧',
    },
    {
      breadcrumbName: '荷物お問い合わせ番号一括取込履歴',
    },
  ];

  const handleRowClick = (record) => {
    dispatch(push(`/orders/airLogi-import/history/${record.objectId}?stt=1`));
  };

  return (
    <div className="airLogi-import-history-list">
      <PageHeader
        breadcrumbRoutes={breadcrumbItems}
        onBack={() => dispatch(push('/orders'))}
        title="荷物お問い合わせ番号一括取込履歴"
      />
      <Card
        className="bg-transparent"
        bodyStyle={{ paddingTop: 0 }}
        bordered={false}
      >
        <Card
          bordered={false}
          className="highlight-card padding-card"
        >
          <Table
            onRowClick={handleRowClick}
            columns={columns}
            data={airLogiImportHistoryList?.list}
            total={airLogiImportHistoryList?.total}
            loading={tableLoading}
            onChange={onTableChange}
            pagination={{
              current: Number(page),
              pageSize: Number(limit),
              marginRight: true,
            }}
          />
        </Card>
      </Card>
    </div>
  );
};

export default AirLogiHistory;
