export const GET_ORDER_LIST_REQUEST = 'OrderProvider/GET_ORDER_LIST_REQUEST';
export const GET_ORDER_LIST_SUCCESS = 'OrderProvider/GET_ORDER_LIST_SUCCESS';
export const GET_ORDER_LIST_ERROR = 'OrderProvider/GET_ORDER_LIST_ERROR';

export const GET_ORDER_DETAIL_REQUEST = 'OrderProvider/GET_ORDER_DETAIL_REQUEST';
export const GET_ORDER_DETAIL_SUCCESS = 'OrderProvider/GET_ORDER_DETAIL_SUCCESS';
export const GET_ORDER_DETAIL_ERROR = 'OrderProvider/GET_ORDER_DETAIL_ERROR';

export const UPDATE_ORDER_ITEMS_REQUEST = 'OrderProvider/UPDATE_ORDER_ITEMS_REQUEST';
export const UPDATE_ORDER_ITEMS_SUCCESS = 'OrderProvider/UPDATE_ORDER_ITEMS_SUCCESS';
export const UPDATE_ORDER_ITEMS_ERROR = 'OrderProvider/UPDATE_ORDER_ITEMS_ERROR';

export const UPDATE_ORDER_STATUS_REQUEST = 'OrderProvider/UPDATE_ORDER_STATUS_REQUEST';
export const UPDATE_ORDER_STATUS_SUCCESS = 'OrderProvider/UPDATE_ORDER_STATUS_SUCCESS';
export const UPDATE_ORDER_STATUS_ERROR = 'OrderProvider/UPDATE_ORDER_STATUS_ERROR';

export const EXPORT_ORDERS_REQUEST = 'OrderProvider/EXPORT_ORDERS_REQUEST';
export const EXPORT_ORDERS_SUCCESS = 'OrderProvider/EXPORT_ORDERS_SUCCESS';
export const EXPORT_ORDERS_ERROR = 'OrderProvider/EXPORT_ORDERS_ERROR';

export const ADD_ITEM_TO_ORDER = 'OrderProvider/ADD_ITEM_TO_ORDER';
export const REMOVE_ITEM_FROM_ORDER = 'OrderProvider/REMOVE_ITEM_FROM_ORDER';

export const GET_ORDER_MESSAGE_LIST_REQUEST = 'OrderProvider/GET_ORDER_MESSAGE_LIST_REQUEST';
export const GET_ORDER_MESSAGE_SUCCESS = 'OrderProvider/GET_ORDER_MESSAGE_SUCCESS';
export const GET_ORDER_MESSAGE_ERROR = 'OrderProvider/GET_ORDER_MESSAGE_ERROR';
export const CREATE_ORDER_MESSAGE_REQUEST = 'OrderProvider/CREATE_ORDER_MESSAGE_REQUEST';
export const CREATE_ORDER_MESSAGE_ERROR = 'OrderProvider/CREATE_ORDER_MESSAGE_ERROR';
export const CREATE_ORDER_MESSAGE_SUCCESS = 'OrderProvider/CREATE_ORDER_MESSAGE_SUCCESS';

export const BULK_UPDATE_ORDERS_REQUEST = 'OrderProvider/BULK_UPDATE_ORDERS_REQUEST';
export const BULK_UPDATE_ORDERS_SUCCESS = 'OrderProvider/BULK_UPDATE_ORDERS_SUCCESS';
export const BULK_UPDATE_ORDERS_ERROR = 'OrderProvider/BULK_UPDATE_ORDERS_ERROR';

export const ADD_NEW_MESSAGE = 'ConversationProvider/ADD_NEW_MESSAGE';

export const EXPORT_ORDER_WITH_PURCHASE_INFO_REQUEST = 'OrderProvider/EXPORT_ORDER_WITH_PURCHASE_INFO_REQUEST';
export const EXPORT_ORDER_WITH_PURCHASE_INFO_SUCCESS = 'OrderProvider/EXPORT_ORDER_WITH_PURCHASE_INFO_SUCCESS';
export const EXPORT_ORDER_WITH_PURCHASE_INFO_ERROR = 'OrderProvider/EXPORT_ORDER_WITH_PURCHASE_INFO_ERROR';

export const UPDATE_TRACKING_INFO_REQUEST = 'OrderProvider/UPDATE_TRACKING_INFO_REQUEST';
export const UPDATE_TRACKING_INFO_SUCCESS = 'OrderProvider/UPDATE_TRACKING_INFO_SUCCESS';
export const UPDATE_TRACKING_INFO_ERROR = 'OrderProvider/UPDATE_TRACKING_INFO_ERROR';

export const EXPORT_ORDER_FOR_BRAND_OWNER_REQUEST = 'OrderProvider/EXPORT_ORDER_FOR_BRAND_OWNER_REQUEST';
export const EXPORT_ORDER_FOR_BRAND_OWNER_SUCCESS = 'OrderProvider/EXPORT_ORDER_FOR_BRAND_OWNER_SUCCESS';
export const EXPORT_ORDER_FOR_BRAND_OWNER_ERROR = 'OrderProvider/EXPORT_ORDER_FOR_BRAND_OWNER_ERROR';

export const SELECT_FILE_AIRLOGI_IMPORT_REQUEST = 'OrderProvider/SELECT_FILE_AIRLOGI_IMPORT_REQUEST';
export const SELECT_FILE_AIRLOGI_IMPORT_SUCCESS = 'OrderProvider/SELECT_FILE_AIRLOGI_IMPORT_SUCCESS';
export const SELECT_FILE_AIRLOGI_IMPORT_ERROR = 'OrderProvider/SELECT_FILE_AIRLOGI_IMPORT_ERROR';

export const IMPORT_FILE_AIRLOGI_REQUEST = 'OrderProvider/IMPORT_FILE_AIRLOGI_REQUEST';
export const IMPORT_FILE_AIRLOGI_SUCCESS = 'OrderProvider/IMPORT_FILE_AIRLOGI_SUCCESS';
export const IMPORT_FILE_AIRLOGI_ERROR = 'OrderProvider/IMPORT_FILE_AIRLOGI_ERROR';

export const GET_AIRLOGI_IMPORT_HISTORY_LIST_REQUEST = 'OrderProvider/GET_AIRLOGI_IMPORT_HISTORY_LIST_REQUEST';
export const GET_AIRLOGI_IMPORT_HISTORY_LIST_SUCCESS = 'OrderProvider/GET_AIRLOGI_IMPORT_HISTORY_LIST_SUCCESS';
export const GET_AIRLOGI_IMPORT_HISTORY_LIST_ERROR = 'OrderProvider/GET_AIRLOGI_IMPORT_HISTORY_LIST_ERROR';

export const GET_AIRLOGI_IMPORT_HISTORY_DETAIL_REQUEST = 'OrderProvider/GET_AIRLOGI_IMPORT_HISTORY_DETAIL_REQUEST';
export const GET_AIRLOGI_IMPORT_HISTORY_DETAIL_SUCCESS = 'OrderProvider/GET_AIRLOGI_IMPORT_HISTORY_DETAIL_SUCCESS';
export const GET_AIRLOGI_IMPORT_HISTORY_DETAIL_ERROR = 'OrderProvider/GET_AIRLOGI_IMPORT_HISTORY_DETAIL_ERROR';

export const RE_REGISTER_ORDER_TO_AIR_LOGI_REQUEST = 'OrderProvider/RE_REGISTER_ORDER_TO_AIR_LOGI_REQUEST';
export const RE_REGISTER_ORDER_TO_AIR_LOGI_SUCCESS = 'OrderProvider/RE_REGISTER_ORDER_TO_AIR_LOGI_SUCCESS';
export const RE_REGISTER_ORDER_TO_AIR_LOGI_ERROR = 'OrderProvider/RE_REGISTER_ORDER_TO_AIR_LOGI_ERROR';

export const UNLINK_ORDER_WITH_AIRLOGI_REQUEST = 'OrderProvider/UNLINK_ORDER_WITH_AIRLOGI_REQUEST';
export const UNLINK_ORDER_WITH_AIRLOGI_SUCCESS = 'OrderProvider/UNLINK_ORDER_WITH_AIRLOGI_SUCCESS';
export const UNLINK_ORDER_WITH_AIRLOGI_ERROR = 'OrderProvider/UNLINK_ORDER_WITH_AIRLOGI_ERROR';

export const STATUS_IMPORT__SELECT_FILE_REQUEST = 'OrderProvider/STATUS_IMPORT__SELECT_FILE_REQUEST';
export const STATUS_IMPORT__SELECT_FILE_SUCCESS = 'OrderProvider/STATUS_IMPORT__SELECT_FILE_SUCCESS';
export const STATUS_IMPORT__SELECT_FILE_ERROR = 'OrderProvider/STATUS_IMPORT__SELECT_FILE_ERROR';

export const STATUS_IMPORT__IMPORT_FILE_REQUEST = 'OrderProvider/STATUS_IMPORT__IMPORT_FILE_REQUEST';
export const STATUS_IMPORT__IMPORT_FILE_SUCCESS = 'OrderProvider/STATUS_IMPORT__IMPORT_FILE_SUCCESS';
export const STATUS_IMPORT__IMPORT_FILE_ERROR = 'OrderProvider/STATUS_IMPORT__IMPORT_FILE_ERROR';

export const STATUS_IMPORT__GET_HISTORY_LIST_REQUEST = 'OrderProvider/STATUS_IMPORT__GET_HISTORY_LIST_REQUEST';
export const STATUS_IMPORT__GET_HISTORY_LIST_SUCCESS = 'OrderProvider/STATUS_IMPORT__GET_HISTORY_LIST_SUCCESS';
export const STATUS_IMPORT__GET_HISTORY_LIST_ERROR = 'OrderProvider/STATUS_IMPORT__GET_HISTORY_LIST_ERROR';

export const STATUS_IMPORT__GET_HISTORY_DETAIL_REQUEST = 'OrderProvider/STATUS_IMPORT__GET_HISTORY_DETAIL_REQUEST';
export const STATUS_IMPORT__GET_HISTORY_DETAIL_SUCCESS = 'OrderProvider/STATUS_IMPORT__GET_HISTORY_DETAIL_SUCCESS';
export const STATUS_IMPORT__GET_HISTORY_DETAIL_ERROR = 'OrderProvider/STATUS_IMPORT__GET_HISTORY_DETAIL_ERROR';
