/* eslint-disable react/prop-types */
import _get from 'lodash/get';
import React, { useCallback } from 'react';
import csv from 'csv';
import { push } from 'connected-react-router';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  Upload, Dropdown, Menu, Space
} from 'antd';
import {
  UploadOutlined,
  DownOutlined,
  HistoryOutlined,
} from '@ant-design/icons';

import { selectFileForStatusImportRequest } from 'providers/OrderProvider/actions';

import withAuthorize from 'components/HOCs/withAuthorize';
import { USER_ROLE } from 'utils/constants';
import toastMessage from 'utils/toastMessage';

/**
 * Import history dropdown menu component
 */
const ImportHistoryMenu = ({ onClick }) => (
  <Menu onClick={onClick}>
    <Menu.Item key="/orders/status-imports/history" icon={<HistoryOutlined />}>
      取込履歴
    </Menu.Item>
  </Menu>
);

/**
 * CSV file upload button component
 */
const CsvUploadButton = ({ onUpload }) => (
  <Upload
    beforeUpload={onUpload}
    accept=".csv"
    maxCount={1}
    showUploadList={false}
  >
    <Space>
      <UploadOutlined />
      注文ステータス一括取込
    </Space>
  </Upload>
);

/**
 * StatusImportDropdown - Component for importing AirLogi CSV data
 * Only visible to SHIKOMEL type suppliers
 */
const StatusImportDropdown = withAuthorize([USER_ROLE.SUPPLIER])(() => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const dispatch = useDispatch();

  // Only show for SHIKOMEL suppliers
  if (_get(currentUser, 'supplier.type') !== 'SHIKOMEL') {
    return null;
  }

  // Handle history menu navigation
  const handleMenuSelect = useCallback(
    ({ key }) => {
      dispatch(push(key));
    },
    [dispatch]
  );

  // Handle CSV file upload
  const handleFileUpload = useCallback(
    (file, fileList) => {
      // Validate file type
      if (file?.type !== 'text/csv') {
        toastMessage.error({
          description: 'ファイル拡張子が正しくありません',
        });
        return false;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        csv.parse(e.target.result, async (err, data) => {
          if (err) {
            toastMessage.error({ description: err?.message });
            return;
          }

          await dispatch(
            selectFileForStatusImportRequest({
              data,
              fileList,
            })
          );
          dispatch(push('/orders/status-imports'));
        });
      };
      reader.readAsText(file);

      return false;
    },
    [dispatch]
  );

  return (
    <Dropdown.Button
      overlay={<ImportHistoryMenu onClick={handleMenuSelect} />}
      icon={<DownOutlined />}
    >
      <CsvUploadButton onUpload={handleFileUpload} />
    </Dropdown.Button>
  );
});

export default StatusImportDropdown;
