import _get from 'lodash/get';
import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  Dropdown, Button, Space, Menu
} from 'antd';
import {
  DownOutlined,
  DownloadOutlined,
  SolutionOutlined,
  UnorderedListOutlined,
  TruckOutlined,
} from '@ant-design/icons';

import withAuthorize from 'components/HOCs/withAuthorize';
import { USER_ROLE } from 'utils/constants';
import { exportOrdersRequest } from 'providers/OrderProvider/actions';
import ExportOrders from '../../Export';

import './style.scss';

// Define export options
const EXPORT_TYPES = {
  DETAILED: 'detailed-order',
  SUMMARY: 'summary-order',
  AIR_LOGI: 'air-logi',
};

// Modal configurations
const MODAL_CONFIGS = {
  [EXPORT_TYPES.DETAILED]: {
    title: '注文明細DL',
    type: undefined,
  },
  [EXPORT_TYPES.SUMMARY]: {
    title: '注文リストDL',
    type: 'SUMMARY_ORDER_LIST',
  },
  [EXPORT_TYPES.AIR_LOGI]: {
    title: 'エアロジ取込用CSVDL',
    type: 'AIR_LOGI_ORDER_LIST',
  },
};

const CSVExportButton = withAuthorize([USER_ROLE.SUPPLIER])(() => {
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  const isShikomelSupplier = _get(currentUser, 'supplier.type') === 'SHIKOMEL';
  const [modalConfig, setModalConfig] = useState(null);

  const handleExport = async (params) => {
    await dispatch(exportOrdersRequest(params));
    setModalConfig(null);
  };

  const handleMenuClick = ({ key }) => {
    const config = MODAL_CONFIGS[key];
    if (!config) return;

    setModalConfig({
      title: config.title,
      exportOrdersRequest: (data) => handleExport(config.type ? { ...data, type: config.type } : data),
    });
  };

  const handleCloseModal = () => setModalConfig(null);

  // Menu options rendering
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={EXPORT_TYPES.DETAILED} icon={<SolutionOutlined />}>
        {MODAL_CONFIGS[EXPORT_TYPES.DETAILED].title}
      </Menu.Item>

      {isShikomelSupplier && (
        <>
          <Menu.Item key={EXPORT_TYPES.SUMMARY} icon={<UnorderedListOutlined />}>
            {MODAL_CONFIGS[EXPORT_TYPES.SUMMARY].title}
          </Menu.Item>
          <Menu.Item key={EXPORT_TYPES.AIR_LOGI} icon={<TruckOutlined />}>
            {MODAL_CONFIGS[EXPORT_TYPES.AIR_LOGI].title}
          </Menu.Item>
        </>
      )}
    </Menu>
  );

  return (
    <div id="CSVExportButton__container">
      {modalConfig && (
        <ExportOrders
          visible
          title={modalConfig.title}
          exportOrdersRequest={modalConfig.exportOrdersRequest}
          handleCloseDialog={handleCloseModal}
        />
      )}

      <Dropdown overlay={menu}>
        <Button type="primary" id="CSVExportButton__button">
          <Space>
            <DownloadOutlined />
            <span id="CSVExportButton__text">CSV出力</span>
            <div id="CSVExportButton__divider" />
            <DownOutlined id="CSVExportButton__down-icon" />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
});

export default CSVExportButton;
