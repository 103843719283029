import moment from 'moment';
import _map from 'lodash/map';
import _get from 'lodash/get';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { Card, Tabs, Tag } from 'antd';

import {
  ORDER_STATUS_COLOR,
  ORDER_STATUS_JP,
  DATE_TIME_FORMAT,
} from 'utils/constants';

import { getAirLogiImportHistoryDetailRequest } from 'providers/OrderProvider/actions';

import Table from 'components/Table';
import PageHeader from 'components/PageHeader';
import useTableControl from 'components/Hooks/useTableControl';
import Columns from '../OperatorAndSupplier_List/Columns';

import './style.less';

const { TabPane } = Tabs;

const ORDER_STATUS = {
  1: '成功件数',
  2: '成功：配送中ステータス',
  3: '成功：時価・不定貫',
  4: 'スキップ件数',
  5: 'スキップ：ステータス不一致',
  6: 'スキップ：既に番号登録済',
  7: 'スキップ：注文番号が存在しない',
  8: 'その他エラー',
};

const AirLogiHistory = () => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  if (_get(currentUser, 'supplier.type') !== 'SHIKOMEL') {
    return <Redirect to="/orders" />;
  }

  const dispatch = useDispatch();
  const airLogiImportHistoryDetail = useSelector(
    (state) => state.orderProvider.airLogiImportHistoryDetail,
    shallowEqual
  );

  const [tableLoading, setTableLoading] = useState(false);
  const { importHistoryId } = useParams();
  const [pageControl, filterControl, onTableChange, onFilterChange] = useTableControl({
    filterKeys: {
      status: 'stt',
    },
  });
  const { page, limit } = pageControl;
  const { status } = filterControl;

  const countFilteredOrderPerStatus = _get(
    airLogiImportHistoryDetail,
    'countResult'
  );

  const userRole = currentUser.role;
  const columnList = Columns(userRole);

  const columns = [
    { ...columnList.icon, title: 'サポートアイコン', dataIndex: 'orderInfo' },
    {
      ...columnList.restaurantInfo,
      dataIndex: ['orderInfo', 'restaurant'],
    },
    { ...columnList.ID, dataIndex: ['orderInfo', 'objectId'] },
    { ...columnList.createdAt, dataIndex: ['orderInfo', 'createdAt'] },
    { ...columnList.trackingInfo, dataIndex: ['orderInfo', 'trackingInfo'] },
    {
      ...columnList.deliveryPrefecture,
      dataIndex: ['orderInfo', 'deliveryInfo', 'deliveryPrefecture'],
    },
    {
      ...columnList.deliveryDate,
      dataIndex: ['orderInfo', 'deliveryInfo', 'deliveryDate'],
    },
    { ...columnList.totalPrice, dataIndex: ['orderInfo', 'total'] },
    {
      ...columnList.status,
      dataIndex: ['orderInfo', 'status'],
      render: (record) => (
        <div style={{ width: 'fit-content' }}>
          <Tag color={ORDER_STATUS_COLOR[record]}>
            {ORDER_STATUS_JP[record]}
          </Tag>
        </div>
      ),
    },
  ];

  const column = [
    {
      title: 'ID',
      dataIndex: 'importedOrderId',
      key: 'importedOrderId',
    },
  ];

  const fetchAirLogiImportHistoryDetail = useCallback(async () => {
    setTableLoading(true);
    try {
      await dispatch(
        getAirLogiImportHistoryDetailRequest({
          page,
          limit,
          status,
          importHistoryId,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setTableLoading(false);
  }, [page, limit, status, importHistoryId, dispatch]);

  useEffect(() => {
    fetchAirLogiImportHistoryDetail();
  }, [fetchAirLogiImportHistoryDetail]);

  const onStatusTabsChange = (evtStatus) => {
    onFilterChange({
      status: evtStatus,
    });
  };

  const createdAt = moment(airLogiImportHistoryDetail?.createdAt).format(
    DATE_TIME_FORMAT
  );

  const breadcrumbItems = [
    {
      path: '/orders',
      breadcrumbName: '注文一覧',
    },
    {
      path: '/airLogi-import/history',
      breadcrumbName: '荷物お問い合わせ番号一括取込履歴',
    },
    {
      breadcrumbName: createdAt,
    },
  ];

  return (
    <div id="airLogi-import-history-detail">
      <PageHeader
        breadcrumbRoutes={breadcrumbItems}
        title={createdAt}
        onBack={() => {
          dispatch(replace('/orders/airLogi-import/history'));
        }}
      />
      <Card
        border={false}
        className="bg-transparent"
        bodyStyle={{
          paddingTop: 0,
        }}
      >
        <Card
          bordered={false}
          className="highlight-card padding-card"
          title="荷物お問い合わせ番号一括取込結果"
          headStyle={{ fontSize: 24, fontWeight: 'bold', padding: '0px 16px' }}
        >
          <Tabs defaultActiveKey={status} onChange={onStatusTabsChange}>
            {_map(ORDER_STATUS, (value, key) => (
              <TabPane
                tab={(
                  <>
                    <div id="count-filter-status">
                      {countFilteredOrderPerStatus
                        ? countFilteredOrderPerStatus[key] || 0
                        : 0}
                    </div>
                    <div id="count-filter-status-text">{value}</div>
                  </>
                )}
                key={key}
              />
            ))}
          </Tabs>
          <Table
            columns={['4', '7', '8'].includes(status) ? column : columns}
            data={airLogiImportHistoryDetail?.list}
            total={airLogiImportHistoryDetail?.total}
            loading={tableLoading}
            onChange={onTableChange}
            pagination={{
              current: Number(page),
              pageSize: Number(limit),
            }}
            scroll={{ x: ['4', '7', '8'].includes(status) ? 200 : 1220 }}
          />
        </Card>
      </Card>
    </div>
  );
};
export default AirLogiHistory;
