import _get from 'lodash/get';
import React from 'react';
import csv from 'csv';
import { push } from 'connected-react-router';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Upload, Dropdown, Menu } from 'antd';
import {
  UploadOutlined,
  DownOutlined,
  HistoryOutlined,
} from '@ant-design/icons';

import { selectFileForAirLogiImportRequest } from 'providers/OrderProvider/actions';

import withAuthorize from 'components/HOCs/withAuthorize';
import { USER_ROLE } from 'utils/constants';
import toastMessage from 'utils/toastMessage';

const AirLogiImportDropdown = withAuthorize([USER_ROLE.SUPPLIER])(() => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  if (_get(currentUser, 'supplier.type') !== 'SHIKOMEL') {
    return null;
  }

  const dispatch = useDispatch();

  const handleMenuSelect = ({ key }) => {
    dispatch(push(key));
  };

  const handleUpload = (file, fileList) => {
    if (file?.type !== 'text/csv') {
      toastMessage.error({
        description: 'ファイル拡張子が正しくありません',
      });
      return false;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      csv.parse(e.target.result, async (err, data) => {
        if (err) {
          toastMessage.error({ description: err?.message });
          return;
        }

        await dispatch(
          selectFileForAirLogiImportRequest({
            data,
            fileList,
          })
        );
        dispatch(push('/orders/airLogi-import'));
      });
    };
    reader.readAsText(file);

    return false;
  };

  const menu = (
    <Menu onClick={handleMenuSelect}>
      <Menu.Item
        key="/orders/airLogi-import/history"
        icon={<HistoryOutlined />}
      >
        取込履歴
      </Menu.Item>
    </Menu>
  );

  const airLogiImportButton = (
    <Upload
      beforeUpload={handleUpload}
      accept=".csv"
      maxCount={1}
      showUploadList={false}
    >
      <UploadOutlined />
      　荷物お問い合わせ番号一括取込
    </Upload>
  );

  return (
    <Dropdown.Button overlay={menu} icon={<DownOutlined />}>
      {airLogiImportButton}
    </Dropdown.Button>
  );
});

export default AirLogiImportDropdown;
