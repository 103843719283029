import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';
import _remove from 'lodash/remove';
import _isNumber from 'lodash/isNumber';
import produce from 'immer';

import Helpers from 'utils/helpers';
import {
  GET_ORDER_LIST_REQUEST,
  GET_ORDER_LIST_SUCCESS,
  GET_ORDER_LIST_ERROR,
  GET_ORDER_DETAIL_REQUEST,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDER_DETAIL_ERROR,
  ADD_ITEM_TO_ORDER,
  REMOVE_ITEM_FROM_ORDER,
  GET_ORDER_MESSAGE_LIST_REQUEST,
  GET_ORDER_MESSAGE_ERROR,
  GET_ORDER_MESSAGE_SUCCESS,
  CREATE_ORDER_MESSAGE_REQUEST,
  CREATE_ORDER_MESSAGE_SUCCESS,
  CREATE_ORDER_MESSAGE_ERROR,
  BULK_UPDATE_ORDERS_REQUEST,
  BULK_UPDATE_ORDERS_SUCCESS,
  BULK_UPDATE_ORDERS_ERROR,
  ADD_NEW_MESSAGE,
  UPDATE_TRACKING_INFO_REQUEST,
  UPDATE_TRACKING_INFO_SUCCESS,
  UPDATE_TRACKING_INFO_ERROR,
  SELECT_FILE_AIRLOGI_IMPORT_REQUEST,
  SELECT_FILE_AIRLOGI_IMPORT_SUCCESS,
  SELECT_FILE_AIRLOGI_IMPORT_ERROR,
  GET_AIRLOGI_IMPORT_HISTORY_LIST_ERROR,
  GET_AIRLOGI_IMPORT_HISTORY_LIST_SUCCESS,
  GET_AIRLOGI_IMPORT_HISTORY_DETAIL_ERROR,
  GET_AIRLOGI_IMPORT_HISTORY_DETAIL_SUCCESS,
  STATUS_IMPORT__SELECT_FILE_REQUEST,
  STATUS_IMPORT__SELECT_FILE_SUCCESS,
  STATUS_IMPORT__SELECT_FILE_ERROR,
  STATUS_IMPORT__GET_HISTORY_LIST_REQUEST,
  STATUS_IMPORT__GET_HISTORY_LIST_SUCCESS,
  STATUS_IMPORT__GET_HISTORY_DETAIL_REQUEST,
  STATUS_IMPORT__GET_HISTORY_DETAIL_SUCCESS,
} from './constants';

const initialState = {
  loading: false,
  error: false,
  orderList: {},
  orderDetails: {},
  orderMessages: {
    data: [],
    orderId: '',
    actionLoading: false,
    dataLoading: false,
    error: false,
  },
  actionLoading: false,
  airLogiImport: {
    fileList: [],
    data: {},
  },
  airLogiImportHistoryList: {},
  airLogiImportHistoryDetail: {},
  statusImport: {
    fileList: [],
    data: {},
  },
  statusImportHistoryList: {},
  statusImportHistoryDetail: {},
};

const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER_LIST_REQUEST:
      state.loading = true;
      state.error = false;
      break;
    case GET_ORDER_LIST_SUCCESS:
      state.loading = false;
      state.error = false;
      state.orderList = action.data;
      break;
    case GET_ORDER_LIST_ERROR:
      state.loading = false;
      state.error = action.error;
      break;
    case GET_ORDER_DETAIL_REQUEST:
      state.loading = true;
      state.error = false;
      break;
    case GET_ORDER_DETAIL_SUCCESS:
      state.loading = false;
      state.error = false;
      state.orderDetails = action.payload;
      break;
    case GET_ORDER_DETAIL_ERROR:
      state.loading = false;
      state.error = action.error;
      break;
    case ADD_ITEM_TO_ORDER: {
      const {
        type, value, name, itemIndex
      } = action.data;

      let error;
      if (type === 'NEW_SHIPPING_FEE' && !_isNumber(value)) {
        error = 'Invalid value';
      }
      if (type === 'RETURNED_MENU') {
        if (!_isNumber(value) || !name || name === '') {
          error = 'Invalid value';
        }
      }
      action.data.error = error;

      if (!_get(state, 'orderDetails.updateItems')) {
        state.orderDetails.updateItems = [];
      }
      if (itemIndex === undefined) {
        state.orderDetails.updateItems.push({
          itemIndex: Helpers.generateObjectId(),
          ...action.data,
        });
      } else {
        state.orderDetails.updateItems[
          _findIndex(
            state.orderDetails.updateItems,
            (o) => o.itemIndex === itemIndex
          )
        ] = action.data;
      }
      break;
    }
    case REMOVE_ITEM_FROM_ORDER: {
      _remove(
        state.orderDetails.updateItems,
        (o) => o.itemIndex === action.data
      );
      break;
    }
    case GET_ORDER_MESSAGE_LIST_REQUEST: {
      state.orderMessages.dataLoading = true;
      state.orderMessages.actionLoading = false;
      state.orderMessages.error = false;
      state.orderMessages.orderId = action.data.orderId;
      break;
    }
    case GET_ORDER_MESSAGE_SUCCESS: {
      state.orderMessages.dataLoading = false;
      state.orderMessages.actionLoading = false;
      state.orderMessages.error = false;
      state.orderMessages.data = action.data;
      break;
    }
    case GET_ORDER_MESSAGE_ERROR: {
      state.orderMessages.dataLoading = false;
      state.orderMessages.actionLoading = false;
      state.orderMessages.error = action.error;
      break;
    }
    case CREATE_ORDER_MESSAGE_ERROR: {
      state.orderMessages.dataLoading = false;
      state.orderMessages.actionLoading = false;
      state.orderMessages.error = action.error;
      break;
    }
    case CREATE_ORDER_MESSAGE_SUCCESS: {
      state.orderMessages.dataLoading = false;
      state.orderMessages.actionLoading = false;
      state.orderMessages.error = false;
      break;
    }
    case CREATE_ORDER_MESSAGE_REQUEST: {
      state.orderMessages.actionLoading = true;
      state.orderMessages.dataLoading = false;
      state.orderMessages.error = false;
      break;
    }
    case BULK_UPDATE_ORDERS_REQUEST: {
      state.actionLoading = true;
      state.error = false;
      break;
    }
    case BULK_UPDATE_ORDERS_SUCCESS: {
      state.actionLoading = false;
      state.error = false;
      break;
    }
    case BULK_UPDATE_ORDERS_ERROR: {
      state.actionLoading = false;
      state.error = action.error;
      break;
    }
    case ADD_NEW_MESSAGE: {
      if (state.orderMessages.orderId === action.data.orderId) {
        state.orderMessages.data.push(action.data);
      }
      break;
    }
    case UPDATE_TRACKING_INFO_REQUEST: {
      state.actionLoading = true;
      state.error = false;
      break;
    }
    case UPDATE_TRACKING_INFO_SUCCESS: {
      state.actionLoading = false;
      state.error = false;
      break;
    }
    case UPDATE_TRACKING_INFO_ERROR: {
      state.actionLoading = false;
      state.error = action.error;
      break;
    }
    case SELECT_FILE_AIRLOGI_IMPORT_REQUEST: {
      state.airLogiImport.fileList = action.payload?.fileList;
      state.airLogiImport.data = {};
      break;
    }
    case SELECT_FILE_AIRLOGI_IMPORT_SUCCESS: {
      state.airLogiImport.data = action.payload;
      break;
    }
    case SELECT_FILE_AIRLOGI_IMPORT_ERROR: {
      state.airLogiImport.fileList = [];
      state.airLogiImport.data = {};
      break;
    }
    case GET_AIRLOGI_IMPORT_HISTORY_LIST_SUCCESS: {
      state.airLogiImportHistoryList = action.payload;
      break;
    }
    case GET_AIRLOGI_IMPORT_HISTORY_LIST_ERROR: {
      state.airLogiImportHistoryList = {};
      break;
    }
    case GET_AIRLOGI_IMPORT_HISTORY_DETAIL_SUCCESS: {
      state.airLogiImportHistoryDetail = action.payload;
      break;
    }
    case GET_AIRLOGI_IMPORT_HISTORY_DETAIL_ERROR: {
      state.airLogiImportHistoryDetail = {};
      break;
    }
    case STATUS_IMPORT__SELECT_FILE_REQUEST: {
      state.statusImport.fileList = action.payload?.fileList;
      state.statusImport.data = {};
      break;
    }
    case STATUS_IMPORT__SELECT_FILE_SUCCESS: {
      state.statusImport.data = action.payload;
      break;
    }
    case STATUS_IMPORT__SELECT_FILE_ERROR: {
      state.statusImport.fileList = [];
      state.statusImport.data = {};
      break;
    }
    case STATUS_IMPORT__GET_HISTORY_LIST_REQUEST: {
      state.statusImportHistoryList = {};
      break;
    }
    case STATUS_IMPORT__GET_HISTORY_LIST_SUCCESS: {
      state.statusImportHistoryList = action.payload;
      break;
    }
    case STATUS_IMPORT__GET_HISTORY_DETAIL_REQUEST: {
      state.statusImportHistoryDetail = {};
      break;
    }
    case STATUS_IMPORT__GET_HISTORY_DETAIL_SUCCESS: {
      state.statusImportHistoryDetail = action.payload;
      break;
    }

    default:
  }
  return state;
});

export default reducer;
